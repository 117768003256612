import React from "react"
import lottie from 'lottie-web';




class LottieModule extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
        	instanceID: Math.random().toString(36).slice(-6),
            frameReset: this.props.frameReset
        };

    }

    componentDidMount(){
    	let lem = document.getElementById('lottieElementWrapper' + this.state.instanceID);
    	let lottieElementWrapper = lottie.loadAnimation({
  		  container: lem, 
		  loop: false,
		  autoplay: false,
          frameReset: this.props.frameReset,
		  animationData: this.props.data,
		  rendererSettings: {
		    preserveAspectRatio: 'xMinYMin slice'
		  }
		});
        console.log(this.state.frameReset)
        if(this.state.frameReset !== ""){
    		lottieElementWrapper.addEventListener('complete', () => {
    			lottieElementWrapper.goToAndPlay(this.props.frameReset, false)
    		})
        }
        	
        window.addEventListener('scroll', () => {
    		console.log(`iconAnimation`+ this.state.instanceID)
	    	if(lem !== null){
		      	var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
		      	if(lem.getBoundingClientRect().top - window.innerHeight + lem.getBoundingClientRect().height/2 < 0){
		      		lottieElementWrapper.play();
		      	}
		    }	
		})
    }

    render() {
    	return(
    		<div className="lottieElementWrapper" id={"lottieElementWrapper" + this.state.instanceID}> 
    			
    		</div>
    	)
    }

}

export default LottieModule